document.addEventListener("DOMContentLoaded", function () {
    const postsPerPage = 4;
    const posts = Array.from(document.querySelectorAll(".col-12.col-md-6"));
    const paginationContainer = document.getElementById("pagination");
    const searchInput = document.getElementById("search-input");
    let currentPage = 1;
    let filteredPosts = [...posts]; // Initially, all posts are visible

    function showPage(page) {
        const totalPages = Math.ceil(filteredPosts.length / postsPerPage);
        if (page < 1) page = 1;
        if (page > totalPages) page = totalPages;

        currentPage = page;
        const start = (currentPage - 1) * postsPerPage;
        const end = start + postsPerPage;

        posts.forEach(post => post.style.display = "none"); // Hide all posts
        filteredPosts.slice(start, end).forEach(post => post.style.display = "block"); // Show only paginated posts

        updatePagination();
    }

    function createPaginationButton(text, targetPage, disabled = false, extraClass = "") {
        let li = document.createElement("li");
        li.classList.add("page-item", extraClass);
        if (disabled) li.classList.add("disabled");

        let a = document.createElement("a");
        a.classList.add("page-link");
        a.href = "#";
        a.innerText = text;

        if (!disabled) {
            a.addEventListener("click", function (event) {
                event.preventDefault();
                showPage(targetPage);
            });
        }

        li.appendChild(a);
        return li;
    }

    function updatePagination() {
        paginationContainer.innerHTML = "";
        const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

        if (totalPages <= 1) return; // Hide pagination if only one page

        // First & Previous Buttons (DO NOT receive .active)
        paginationContainer.appendChild(createPaginationButton("", 1, currentPage === 1, "first-page"));
        paginationContainer.appendChild(createPaginationButton("", currentPage - 1, currentPage === 1, "prev-page"));

        // Numbered Page Buttons (ONLY THESE RECEIVE .active)
        for (let i = 1; i <= totalPages; i++) {
            let li = document.createElement("li");
            li.classList.add("page-item");
            if (i === currentPage) li.classList.add("active"); // Only number buttons get .active

            let a = document.createElement("a");
            a.classList.add("page-link");
            a.href = "#";
            a.innerText = i;
            a.addEventListener("click", function (event) {
                event.preventDefault();
                showPage(i);
            });

            li.appendChild(a);
            paginationContainer.appendChild(li);
        }

        // Next & Last Buttons (DO NOT receive .active)
        paginationContainer.appendChild(createPaginationButton("", currentPage + 1, currentPage === totalPages, "next-page"));
        paginationContainer.appendChild(createPaginationButton("", totalPages, currentPage === totalPages, "last-page"));
    }

    function filterPosts() {
        const query = searchInput.value.toLowerCase();

        if (query === "") {
            filteredPosts = [...posts]; // Reset to all posts
        } else {
            filteredPosts = posts.filter(post => {
                const keywords = post.getAttribute("data-keywords").toLowerCase();
                return keywords.includes(query);
            });
        }

        currentPage = 1; // Reset to first page
        showPage(currentPage);
    }

    searchInput.addEventListener("input", filterPosts);
    showPage(currentPage);
});
