if ($('body.page_1').length > 0) {
    addUtmParaFunc()
}

function addUtmParaFunc() {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('utm_source') && urlParams.has('utm_medium') && urlParams.has('utm_campaign')) {
        const utmSource   = urlParams.get('utm_source');
        const utmMedium   = urlParams.get('utm_medium');
        const utmCampaign = urlParams.get('utm_campaign');
    
        Array.from(document.getElementsByTagName('a')).forEach((el) => {
            if (el.getAttribute('href')?.startsWith('/')) {
                el.href = el.getAttribute('href') + '?utm_source=' + utmSource + '&utm_medium=' + utmMedium + '&utm_campaign=' + utmCampaign;
            }
        });
    }
}